<template>
  <h3>Instrumente</h3>
  <select class="form-select" v-model="picked" :size="instruments.length">
    <option v-for="instrument in instruments" v-bind:value="instrument" :key="instrument.id">
      {{ instrument.name }}
    </option>
  </select>
  <div class="mt-2 d-grid gap-2" v-if="userStore.accessLevel > 2">
    <button class="btn btn-primary" @click="download" :disabled="downloadDisabled">Download: Instrumentensatz</button>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Instrument } from "@/_utility_/backend/apiModels/instruments";
import { userStore } from "@/_utility_/stores/userStore";

@Options({
  props: {
    instruments: Array<Instrument>,
    minSize: 0,
    downloadDisabled: false,
  },
  emits: ["selectedInstrument", "download"],
  created() {
    if (this.instruments.length == 1) {
      this.picked = this.instruments[0];
    }
  },
  data() {
    return {
      picked: {},
    };
  },
  watch: {
    picked(instrument) {
      this.$emit("selectedInstrument", instrument);
    },
  },
})
export default class Instruments extends Vue {
  declare picked: Instrument;
  declare instruments: Array<Instrument>;
  declare downloadDisabled: boolean;
  userStore = userStore();

  download(): void {
    this.$emit("download", this.picked);
  }
}
</script>
