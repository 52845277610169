<template>
  <LoadingSpinner :isLoading="isLoading" />
  <div class="container mb-2">
    <div class="row align-items-start mx-2" style="z-index: 1">
      <div class="col-lg-6 gx-2 gy-3" v-if="compositions?.length > 0">
        <div class="m-0">
          <Compositions
            :compositions="compositions"
            :downloadDisabled="selectedCompositions.length != 1 || userStore.accessLevel < 3"
            @selectedComposition="onCompositionSelect"
            @download="downloadBtnCompositionClicked"
          />
        </div>
      </div>
      <div class="col-lg-6 gx-2 gy-3" v-if="availableInstruments.length > 0">
        <div class="mb-3">
          <Instruments
            :instruments="availableInstruments"
            @selectedInstrument="onInstrumentSelection"
            @download="downloadBtnInstrumentClicked"
            :downloadDisabled="partsForInstrument.length <= 0"
          />
        </div>
        <div v-if="partsForInstrument.length > 0">
          <Parts
            :parts="partsForInstrument"
            :downloadSingleDisabled="partSelection.length < 1"
            :downloadMergedDisabled="partSelection.length < 2"
            :selectionCount="partSelection.length"
            @selectedPart="selectedPart"
            @downloadSingle="downloadBtnClicked"
            @downloadMerged="downloadBtnMergeClicked"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Compositions from "@/components/NoteSelection/Compositions.vue";
import Instruments from "@/components/NoteSelection/Instruments.vue";
import Parts from "@/components/NoteSelection/Parts.vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import { IApi } from "@/_utility_/backend/api";
import { Composition } from "@/_utility_/backend/apiModels/compositions";
import { Instrument } from "@/_utility_/backend/apiModels/instruments";
import { Part } from "@/_utility_/backend/apiModels/parts";
import {
  downloadParts,
  downloadPartsForComposition,
  downloadPartsForInstrument,
  downloadPartsMerged,
} from "@/_utility_/downloader";
import { userStore } from "@/_utility_/stores/userStore";
import { isDesktop } from "@/_utility_/deviceRecognizer";
import { TYPES } from "@/_utility_/infrastructure/types";
import { diContainer } from "@/_utility_/infrastructure/diContainer";

@Options({
  components: {
    Compositions,
    Instruments,
    Parts,
    LoadingSpinner,
  },
})
export default class NoteSelection extends Vue {
  declare isLoading: boolean;
  declare compositions: Composition[];
  declare availableInstruments: Instrument[];
  declare partsForInstrument: Part[];
  declare partSelection: Part[];
  declare selectedCompositions: Composition[];
  userStore = userStore();
  declare api: IApi;

  async created() {
    this.isLoading = true;
    this.api = diContainer.get<IApi>(TYPES.Api);
    const compositionResponse = await this.api.getCompositions();
    this.compositions = compositionResponse.compositions;
    this.isLoading = false;
  }

  data() {
    return {
      compositions: [] as Composition[],
      selectedCompositions: [] as Composition[],
      availableInstruments: new Array<Instrument>(),
      filteredFiles: [],
      isLoading: false,
      partSelection: [] as Part[],
    };
  }

  async onCompositionSelect(compositions: Composition[], pressedEnter: boolean) {
    this.isLoading = true;
    this.availableInstruments = [];
    this.partsForInstrument = [];

    if (compositions.length > 1 && (this.userStore.accessLevel < 3 || (isDesktop() && !pressedEnter))) {
      this.isLoading = false;
      console.log("Suppress multi select on desktop or due to accessLevel. Press ENTER");
      return;
    }

    const instrumentResponse = await this.api.getInstruments(compositions);
    this.availableInstruments = instrumentResponse.success ? instrumentResponse.instruments : [];
    this.isLoading = false;
    this.selectedCompositions = compositions;
  }
  async onInstrumentSelection(instrument: Instrument) {
    this.isLoading = true;
    this.partsForInstrument = [];
    this.partSelection = [];
    const partsResponse = await this.api.getParts(this.selectedCompositions, instrument);
    if (partsResponse.success) this.partsForInstrument = partsResponse.parts;
    this.isLoading = false;
  }
  selectedPart(parts: Part[]) {
    this.isLoading = true;
    this.partSelection = parts;
    this.isLoading = false;
  }
  async downloadBtnClicked() {
    this.isLoading = true;
    await downloadParts(this.partSelection);
    this.isLoading = false;
  }
  async downloadBtnMergeClicked() {
    this.isLoading = true;
    await downloadPartsMerged(this.partSelection);
    this.isLoading = false;
  }
  async downloadBtnCompositionClicked() {
    this.isLoading = true;
    await downloadPartsForComposition(this.selectedCompositions[0]);
    this.isLoading = false;
  }
  async downloadBtnInstrumentClicked(instrument: Instrument) {
    this.isLoading = true;
    await downloadPartsForInstrument(this.selectedCompositions, instrument);
    this.isLoading = false;
  }
}
</script>

<style scoped>
#loadingOverlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
}
</style>
