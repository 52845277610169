<template>
  <div class="container">
    <div class="alert alert-danger d-flex align-items-center" role="alert">
      <i class="bi flex-shrink-0 me-2 bi-exclamation-triangle-fill" />
      <div>
        Ein unbekannter Fehler ist aufgetreten! Wende Dich bitte an den Administrator.
        <div>
          <small>{{ this.errorMessage ?? "" }}</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    errorMessage: "",
  },
})
export default class ErrorAlert extends Vue {}
</script>
