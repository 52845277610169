import { BaseApiResult } from "../baseResponse";

export class CompositionResponse extends BaseApiResult {
  declare compositions: Composition[];
}

export class Composition {
  declare id: string;
  declare name: string;
}
