<template>
  <div class="mx-auto img-width">
    <img class="img-fluid mx-auto w-100" alt="MVL logo" src="./assets/logo.png" />
  </div>
  <Login v-if="userStore.accessLevel <= 1" />
  <RouterView v-if="userStore.accessLevel > 1" />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Login from "@/components/Login/Login.vue";
import ErrorAlert from "@/components/general/ErrorAlert.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { userStore } from "@/_utility_/stores/userStore";

@Options({
  components: {
    Login,
    ErrorAlert,
  },
})
export default class App extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userStore: any;
  declare errorOccured: boolean;
  declare errorMessage: Error;

  data() {
    const store = userStore();
    return {
      userStore: store,
      errorOccured: false,
    };
  }
  showError(error: Error) {
    this.errorOccured = true;
    this.errorMessage = error;
  }
}
</script>

<style lang="scss" global>
$primary: rgb(149, 29, 39);

// Bootstrap and its default variables
@import "node_modules/bootstrap/scss/bootstrap";
</style>

<style scoped>
.img-width {
  max-width: 800px;
}
</style>
