import "reflect-metadata";
import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import { userStore } from "./_utility_/stores/userStore";
import { diContainer } from "./_utility_/infrastructure/diContainer";
import { TYPES } from "./_utility_/infrastructure/types";
import { ILogger } from "./_utility_/logger/appInsightsLogger";
import router from "./infrastructure/routes";

const logger = diContainer.get<ILogger>(TYPES.Logger);
logger.trackPageView();

const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(router);
app.mount("#app");

const store = userStore();
store.initializeFromSessionStorage();
