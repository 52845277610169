<template>
  <div class="container">
    <div class="container m-0 p-0 input-group mb-3">
      <span class="col-4 input-group-text" id="userlabel">Username</span>
      <input
        class="col-8 form-control"
        :class="{
          'is-valid': isValidUsername,
          'is-invalid': !isValidUsername && triedInvalidSubmit,
        }"
        v-model="username"
        type="text"
        placeholder="Username"
        aria-label="Username"
        aria-describedby="userlabel"
        autocomplete="username"
        @keydown.enter="onLogin"
      />
      <div class="invalid-feedback alert alert-danger mt-2 mb-0 px-2 py-1" v-if="isRegistration">
        Muster: <b><i>VornameNachname</i></b>
        <br />
        (Groß-/Kleinschreibung beachten)
      </div>
    </div>
    <div class="container m-0 p-0 input-group mb-3">
      <span class="col-4 input-group-text" id="pwlabel">Passwort</span>
      <input
        class="col-8 form-control"
        :class="{
          'is-valid': isValidPassword,
          'is-invalid': !isValidPassword && triedInvalidSubmit,
        }"
        type="password"
        v-model="password"
        placeholder="Passwort"
        aria-label="Passwort"
        aria-describedby="pwlabel"
        autocomplete="current-password"
        @keydown.enter="onLogin"
      />
      <div class="invalid-feedback alert alert-danger mt-2 mb-0 px-2 py-1" v-if="isRegistration">
        Passwortanforderungen:
        <ul class="m-0">
          <li>mind. 6 Zeichen</li>
          <li>mind. ein Kleinbuchstabe</li>
          <li>mind. ein Großbuchstabe</li>
          <li>mind. eine Ziffer</li>
        </ul>
      </div>
    </div>
    <div v-if="isRegistration" class="container m-0 p-0 input-group mb-3">
      <span class="col-4 input-group-text" id="instrumentlabel">Instrument</span>
      <select
        v-model="instrument"
        class="form-select"
        :class="{
          'is-valid': isValidInstrument,
          'is-invalid': !isValidInstrument && triedInvalidSubmit,
        }"
      >
        <option value="" disabled selected>Bitte auswählen</option>
        <option v-for="instrument in instruments" :key="instrument">
          {{ instrument }}
        </option>
      </select>
    </div>
    <div class="container m-0 p-0 btn-group mb-3">
      <button @click="onRegister" class="col-5 btn btn-outline-primary">Registrieren</button>
      <button class="btn btn-outline-primary col-1">
        <div class="spinner-border spinner-border-sm" v-if="isLoading" />
      </button>
      <button @click="onLogin" class="col-5 btn btn-primary" type="submit">Login</button>
    </div>
    <div v-if="loginFailed" class="alert alert-warning d-flex align-items-center" role="alert">
      <i class="bi flex-shrink-0 me-2 bi-exclamation-triangle" />
      <div>Login Fehlgeschlagen!</div>
    </div>
    <div v-if="registerFailed" class="alert alert-warning d-flex align-items-center" role="alert">
      <i class="bi flex-shrink-0 me-2 bi-exclamation-triangle" />
      <div>
        Registrierung fehlgeschlagen! Wende Dich bitte an den Administrator.
        <br /><small><b>Fehlermeldung:</b> {{ errorMessage }}</small>
      </div>
    </div>
    <div v-if="unconfirmed" class="alert alert-info d-flex align-items-center" role="alert">
      <i class="bi flex-shrink-0 me-2 bi-info-circle" />
      <div>
        Der Login war erfolgreich, allerdings wurde der Benutzer noch nicht freigeschaltet. Wende Dich bitte an den
        Administrator.
      </div>
    </div>
    <div v-if="registered" class="alert alert-success d-flex align-items-center" role="alert">
      <i class="bi flex-shrink-0 me-2 bi-info-circle" />
      <div>
        Die Registrierung war erfolgreich, allerdings wurde der Benutzer noch nicht freigeschaltet. Wende Dich dazu
        bitte an den Administrator.
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { loginAndSetUserInfo } from "@/_utility_/backend/login";
import { getInstrumentGroups } from "@/_utility_/conversion/validValues";
import { IApi } from "@/_utility_/backend/api";
import { userStore } from "@/_utility_/stores/userStore";
import { diContainer } from "@/_utility_/infrastructure/diContainer";
import { TYPES } from "@/_utility_/infrastructure/types";

@Options({
  methods: {
    async onRegister() {
      await this.wrapInLoadingAndResetAlerts(async () => {
        if (!this.isRegistration) {
          this.isRegistration = true;
          return;
        }
        if (!this.isValid()) {
          this.triedInvalidSubmit = true;
          return;
        }
        const response = await this.api.register(this.username, this.password, this.instrument);
        this.registered = response.success;
        this.registerFailed = !response.success;
        this.errorMessage = response.errorMessage;
      });
    },
    isValid(isRegistration = true) {
      return this.isValidUsername && this.isValidPassword && (isRegistration ? this.isValidInstrument : true);
    },
    async onLogin() {
      await this.wrapInLoadingAndResetAlerts(async () => {
        if (!this.isValid(false)) {
          this.triedInvalidSubmit = true;
          return;
        }
        this.resetUserInformation();
        await loginAndSetUserInfo(this.username, this.password);
        if (this.store.accessLevel < 1) {
          this.loginFailed = true;
        } else if (this.store.accessLevel == 1) {
          this.unconfirmed = true;
        }
      });
    },
    resetAlerts() {
      this.loginFailed = false;
      this.unconfirmed = false;
      this.registerFailed = false;
      this.registered = false;
      this.errorMessage = "";
      this.triedInvalidSubmit = false;
    },
    resetUserInformation() {
      this.store.clearState();
      this.loginFailed = false;
      this.unconfirmed = false;
    },
    async wrapInLoadingAndResetAlerts(func: () => Promise<void>) {
      this.isLoading = true;
      this.resetAlerts();
      await func();
      this.isLoading = false;
    },
  },
  computed: {
    isValidUsername() {
      const regex = new RegExp("([A-Z][a-z]{2,}){2}");
      const match = this.username.match(regex);
      return match && match[0] === this.username;
    },
    isValidPassword() {
      let valid = true;

      valid &&= this.password.length >= 6;

      valid &&= /[a-z]/.test(this.password);
      valid &&= /[A-Z]/.test(this.password);
      valid &&= /[0-9]/.test(this.password);

      return valid;
    },
    isValidInstrument() {
      return this.instrument;
    },
  },
  created() {
    this.api = diContainer.get<IApi>(TYPES.Api);
    this.instruments = getInstrumentGroups();
  },
  data() {
    return {
      username: "",
      password: "",
      instrument: "",
      instruments: [] as string[],
      isRegistration: false,
      loginFailed: false,
      unconfirmed: false,
      registerFailed: false,
      registered: false,
      shallValidate: false,
      triedInvalidSubmit: false,
      errorMessage: "",
      isLoading: false,
    };
  },
})
export default class Login extends Vue {
  declare username: string;
  declare isValidUsername: boolean;
  declare triedInvalidSubmit: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  declare onLogin: any;
  declare isRegistration: boolean;
  declare password: string;
  declare isValidPassword: boolean;
  declare instrument: string;
  declare isValidInstrument: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  declare errorMessage: string;
  declare instruments: string[];
  declare onRegister: ((payload: MouseEvent) => void) | undefined;
  declare loginFailed: boolean;
  declare registerFailed: boolean;
  declare unconfirmed: boolean;
  declare registered: boolean;
  declare isLoading: boolean;
  declare api: IApi;
  store = userStore();
}
</script>

<style scoped>
.container {
  max-width: 400px;
}
</style>
