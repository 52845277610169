import { from } from "linq-to-typescript";
import { BaseApiResult } from "../baseResponse";
import { Composition } from "./compositions";

export class InstrumentsRequest {
  declare compositionIds: string[];
  constructor(compositions: Composition[]) {
    this.compositionIds = from(compositions)
      .select((composition) => composition.id)
      .toArray();
  }
}

export class InstrumentsResponse extends BaseApiResult {
  declare instruments: Instrument[];
}

export class Instrument {
  declare id: string;
  declare name: string;
}
