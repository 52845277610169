import UserInfo from "../backend/UserInfo";
import { decode } from "jsonwebtoken";
import { TokenBodyModel, TokenModel } from "../backend/apiModels/user";
import { plainToClass } from "class-transformer";

const accessLevelKey = "accessLevel";
const tokenKey = "token";
const userIdKey = "userId";
const instrumentKey = "instrument";
const storage = sessionStorage;

export function setUserState(userInfo: UserInfo, token: string, tokenBody: TokenBodyModel) {
  try {
    if (tokenBody.isExpired()) {
      storage.clear();
      return;
    }
    storage.setItem(tokenKey, token);
    storage.setItem(userIdKey, userInfo.userId.toString());
    storage.setItem(accessLevelKey, userInfo.accessLevel.toString());
    storage.setItem(instrumentKey, userInfo.instrument);
  } catch (err) {
    console.error(err);
  }
}

export function getUserState(): UserInfo | undefined {
  try {
    const token = storage.getItem(tokenKey);
    if (token) {
      const decoded = plainToClass(TokenBodyModel, decode(token));
      if (decoded.isExpired()) {
        storage.clear();
        return undefined;
      }
      return new UserInfo().setUserInfo(decoded);
    }
    return undefined;
  } catch {
    return undefined;
  }
}

export function clearUserState(): void {
  storage.clear();
}

export function getToken(): TokenModel {
  return new TokenModel(storage.getItem(tokenKey));
}
