import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import appInsightsConfig from "./appInsightsConfig";
import { injectable } from "inversify";

export interface ILogger {
  init(): ILogger;
  trackPageView(): void;
}

@injectable()
export class AppInsightsLogger implements ILogger {
  declare appInsights: ApplicationInsights;

  public init(): ILogger {
    this.appInsights = new ApplicationInsights({
      config: { instrumentationKey: appInsightsConfig.instrumentationKey },
    });
    this.appInsights.loadAppInsights();
    return this;
  }

  public trackPageView(): void {
    this.appInsights.trackPageView();
  }
}
