import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { TokenBodyModel, TokenModel } from "../backend/apiModels/user";
import { decode } from "jsonwebtoken";
import { plainToClass } from "class-transformer";
import UserInfo from "../backend/UserInfo";
import { clearUserState, getToken, getUserState, setUserState } from "../state/userState";

export const userStore = defineStore("user", () => {
  const token = ref("");
  const userId = ref(0);
  const accessLevel = ref(0);
  const instrument = ref("");

  const tokenModel = computed(() => new TokenModel(token.value));

  const userInfo = computed(() => {
    try {
      if (token.value != "") {
        const decoded = plainToClass(TokenBodyModel, decode(token.value));
        if (decoded.isExpired()) {
          clearState();
          return undefined;
        }
        return new UserInfo().setUserInfo(decoded);
      }
      return new UserInfo();
    } catch {
      return undefined;
    }
  });

  function setUserInfo(userInfo: UserInfo, rawToken: string, tokenBody: TokenBodyModel) {
    try {
      if (tokenBody.isExpired()) {
        clearState();
        return;
      }
      token.value = rawToken;
      userId.value = userInfo.userId;
      accessLevel.value = userInfo.accessLevel;
      instrument.value = userInfo.instrument;
      setUserState(userInfo, rawToken, tokenBody);
    } catch (err) {
      console.error(err);
    }
  }

  function initializeFromSessionStorage() {
    const state = getUserState();
    userId.value = state?.userId ?? 0;
    accessLevel.value = state?.accessLevel ?? 0;
    instrument.value = state?.instrument ?? "";

    token.value = getToken().token ?? "";
  }

  function clearState() {
    token.value = "";
    userId.value = 0;
    accessLevel.value = 0;
    instrument.value = "";
    clearUserState();
  }

  return {
    token,
    userId,
    accessLevel,
    instrument,
    tokenModel,
    userInfo,
    setUserInfo,
    clearState,
    initializeFromSessionStorage,
  };
});
