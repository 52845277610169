<template>
  <div class="d-flex" id="loadingOverlay" v-if="isLoading">
    <div class="m-auto spinner-grow text-primary" style="width: 5rem; height: 5rem; z-index: 1000" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    isLoading: false,
  },
})
export default class LoadingSpinner extends Vue {}
</script>
