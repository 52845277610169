<template>
  <h3>Stimmen</h3>
  <div class="form-check form-switch">
    <input class="form-check-input" type="checkbox" role="switch" id="filterPart" v-model="togglePartNumberFilter" />
    <label class="form-check-label" for="filterPart">Filtern nach Stimme</label>
  </div>
  <div class="collapse" id="partSelect">
    Stimme:
    <select multiple class="form-select mb-2" v-model="pickedPartNumbers" :size="availablePartNumbers.length">
      <option v-for="part in availablePartNumbers" v-bind:value="part" :key="part.id">
        {{ part.displayName }}
      </option>
    </select>
  </div>
  <div class="form-check form-switch">
    <input
      class="form-check-input"
      type="checkbox"
      role="switch"
      id="toggleTuningFilter"
      v-model="toggleTuningFilter"
    />
    <label class="form-check-label" for="toggleTuningFilter">Filtern nach Stimmung</label>
  </div>
  <div class="collapse" id="tuningSelect">
    Stimmung:
    <select multiple class="form-select mb-2" v-model="pickedTunings" :size="getSizeForLength(availableTunings.length)">
      <option v-for="tuning in availableTunings" v-bind:value="tuning" :key="tuning">
        {{ tuning }}
      </option>
    </select>
  </div>
  <div class="collapse show" id="fileSelect">
    Dateien:
    <select multiple class="form-select mb-2" v-model="pickedParts" :size="getSizeForLength(filteredByTunings.length)">
      <option v-for="part in filteredByTunings" v-bind:value="part" :key="part.id">
        {{ part.id }}
      </option>
    </select>
  </div>
  <div class="d-grid gap-2">
    <div class="btn-group">
      <button class="btn btn-primary" @click="downloadSingle" :disabled="downloadSingleDisabled">
        Download einzeln
        {{ selectionCount > 0 ? "(" + selectionCount + ")" : "" }}
      </button>
      <button class="btn btn-primary" @click="downloadMerged" :disabled="downloadMergedDisabled">
        Download in einer Datei
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Collapse } from "bootstrap";
import { Part, PartNumber } from "@/_utility_/backend/apiModels/parts";
import { from } from "linq-to-typescript";

@Options({
  props: {
    parts: Array<Part>,
    downloadSingleDisabled: false,
    downloadMergedDisabled: false,
    selectionCount: 0,
  },
  emits: ["selectedPart", "downloadSingle", "downloadMerged"],
  watch: {
    pickedParts(parts) {
      this.$emit("selectedPart", parts);
    },
    parts() {
      //reset everything!
      this.pickedParts = [];
      this.pickedPartNumbers = [];
      this.pickedTunings = [];
    },
    pickedPartNumbers() {
      this.pickedParts = [];
    },
    pickedTunings() {
      this.pickedParts = [];
    },
    togglePartNumberFilter(filter: boolean) {
      this.collapse("#partSelect");
      if (!filter) {
        this.pickedPartNumbers = [];
      }
      this.pickedParts = [];
    },
    toggleTuningFilter(filter: boolean) {
      this.collapse("#tuningSelect");
      if (!filter) {
        this.pickedTunings = [];
      }
      this.pickedParts = [];
    },
    showFiles() {
      this.collapse("#fileSelect");
    },
  },
  computed: {
    showFiles(): boolean {
      return this.filteredByTunings.length > 0;
    },
  },
})
export default class Parts extends Vue {
  declare togglePartNumberFilter: boolean;
  declare pickedPartNumbers: PartNumber[];
  declare toggleTuningFilter: boolean;
  declare pickedTunings: string[];
  declare pickedParts: Part[];
  declare downloadSingleDisabled: boolean;
  declare selectionCount: number;
  declare downloadMergedDisabled: boolean;
  declare parts: Array<Part>;

  data() {
    return {
      togglePartNumberFilter: false,
      toggleTuningFilter: false,
      pickedParts: [],
      pickedPartNumbers: [],
      pickedTunings: [],
    };
  }

  //computed properties
  get availablePartNumbers(): PartNumber[] {
    return from(this.parts)
      .select((part) => part.partNumber)
      .distinct((a, b) => a.id == b.id)
      .toArray();
  }

  get availableTunings(): string[] {
    return from(this.parts)
      .select((part) => part.tuning)
      .distinct()
      .toArray();
  }

  get filteredByParts(): Part[] {
    if (this.togglePartNumberFilter) {
      const parts = from(this.parts);
      const partNumbers = from(this.pickedPartNumbers);
      return parts.where((part) => partNumbers.any((partNumber) => part.partNumber.id == partNumber.id)).toArray();
    }
    return this.parts;
  }

  get filteredByTunings(): Part[] {
    if (this.toggleTuningFilter) {
      return from(this.filteredByParts)
        .where((part) => from(this.pickedTunings).any((tuning) => part.tuning == tuning))
        .toArray();
    }
    return this.filteredByParts;
  }

  collapse(id: string): void {
    const collapseElementList = [].slice.call(document.querySelectorAll(id));
    collapseElementList.map(function (collapseEl) {
      return new Collapse(collapseEl);
    });
  }

  getSizeForLength(length: number): number {
    return Math.max(length, 1);
  }

  downloadSingle(): void {
    this.$emit("downloadSingle");
  }

  downloadMerged(): void {
    this.$emit("downloadMerged");
  }
}
</script>
