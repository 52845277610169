import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3242151e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container mb-2" }
const _hoisted_2 = {
  class: "row align-items-start mx-2",
  style: {"z-index":"1"}
}
const _hoisted_3 = {
  key: 0,
  class: "col-lg-6 gx-2 gy-3"
}
const _hoisted_4 = { class: "m-0" }
const _hoisted_5 = {
  key: 1,
  class: "col-lg-6 gx-2 gy-3"
}
const _hoisted_6 = { class: "mb-3" }
const _hoisted_7 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_Compositions = _resolveComponent("Compositions")!
  const _component_Instruments = _resolveComponent("Instruments")!
  const _component_Parts = _resolveComponent("Parts")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_LoadingSpinner, { isLoading: _ctx.isLoading }, null, 8, ["isLoading"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.compositions?.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_Compositions, {
                  compositions: _ctx.compositions,
                  downloadDisabled: _ctx.selectedCompositions.length != 1 || _ctx.userStore.accessLevel < 3,
                  onSelectedComposition: _ctx.onCompositionSelect,
                  onDownload: _ctx.downloadBtnCompositionClicked
                }, null, 8, ["compositions", "downloadDisabled", "onSelectedComposition", "onDownload"])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.availableInstruments.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_Instruments, {
                  instruments: _ctx.availableInstruments,
                  onSelectedInstrument: _ctx.onInstrumentSelection,
                  onDownload: _ctx.downloadBtnInstrumentClicked,
                  downloadDisabled: _ctx.partsForInstrument.length <= 0
                }, null, 8, ["instruments", "onSelectedInstrument", "onDownload", "downloadDisabled"])
              ]),
              (_ctx.partsForInstrument.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_Parts, {
                      parts: _ctx.partsForInstrument,
                      downloadSingleDisabled: _ctx.partSelection.length < 1,
                      downloadMergedDisabled: _ctx.partSelection.length < 2,
                      selectionCount: _ctx.partSelection.length,
                      onSelectedPart: _ctx.selectedPart,
                      onDownloadSingle: _ctx.downloadBtnClicked,
                      onDownloadMerged: _ctx.downloadBtnMergeClicked
                    }, null, 8, ["parts", "downloadSingleDisabled", "downloadMergedDisabled", "selectionCount", "onSelectedPart", "onDownloadSingle", "onDownloadMerged"])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}