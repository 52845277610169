import UserInfo from "@/_utility_/backend/UserInfo";
import { TokenBodyModel } from "../backend/apiModels/user";
import { decode } from "jsonwebtoken";
import { plainToClass } from "class-transformer";
import { IApi } from "./api";
import { userStore } from "../stores/userStore";
import { diContainer } from "../infrastructure/diContainer";
import { TYPES } from "../infrastructure/types";

export async function loginAndSetUserInfo(username: string, password: string): Promise<void> {
  try {
    const store = userStore();
    const api = diContainer.get<IApi>(TYPES.Api);
    const response = await api.login(username, password);
    if (response.success) {
      const decoded = plainToClass(TokenBodyModel, decode(response.token));
      const userInfo = new UserInfo().setUserInfo(decoded);
      store.setUserInfo(userInfo, response.token, decoded);
    }
  } catch (e) {
    console.error(e);
  }
}
