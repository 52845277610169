import { BaseApiResult } from "../baseResponse";
import { Composition } from "./compositions";
import { Instrument } from "./instruments";

export class PartsRequest {
  declare compositionIds: string[];
  declare instrumentId: string;

  public constructor(init?: Partial<PartsRequest>) {
    Object.assign(this, init);
  }
}

export class PartsResponse extends BaseApiResult {
  declare parts: Part[];
}

export class Part {
  declare id: string;
  declare partNumber: PartNumber;
  declare composition: Composition;
  declare instrument: Instrument;
  declare tuning: string;
  declare clef: string;
}

export class PartNumber {
  declare id: string;
  declare displayName: string;
}
