import { Container } from "inversify";
import { Api, IApi } from "../backend/api";
import { TYPES } from "./types";
import { AppInsightsLogger, ILogger } from "../logger/appInsightsLogger";
import HttpClient, { IHttpClient } from "../backend/httpClient";

const container = new Container();
container.bind<IApi>(TYPES.Api).to(Api).inTransientScope();
container.bind<IHttpClient>(TYPES.HttpClient).to(HttpClient).inTransientScope();

container
  .bind<ILogger>(TYPES.Logger)
  .to(AppInsightsLogger)
  .inSingletonScope()
  .onActivation((_, instance) => instance.init());

export { container as diContainer };
