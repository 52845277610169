import { injectable } from "inversify";
import { userStore } from "../stores/userStore";

export interface IHttpClient {
  send(url: URL, httpMethod: string, requestBody?: unknown): Promise<Response>;
}

@injectable()
export default class HttpClient implements IHttpClient {
  async send(url: URL, httpMethod: string, requestBody?: unknown): Promise<Response> {
    const store = userStore();
    const token = store.tokenModel;
    const response = await fetch(url, {
      method: httpMethod,
      headers: {
        "Content-Type": "application/json",
        Authorization: token.toHeaderValue(),
      },
      body: requestBody ? JSON.stringify(requestBody) : null,
      mode: "cors", // todo only allow same origin with port 3000
    });
    if (response.status === 401) store.clearState();
    return response;
  }
}

export class HttpMethod {
  public static Post = "post";
  public static Get = "get";
}

export function isSuccess(response: Response): boolean {
  return response.ok;
}
