const instrumentGroups = {
  Floete: ["Floete", "Piccolo", "Oboe", "Lyra"],
  Fagott: ["Fagott"],
  Klarinette: ["Klarinette", "AltKlarinette", "BassKlarinette"],
  Saxophon: ["SopranSaxophon", "AltSaxophon", "TenorSaxophon", "BaritonSaxophon"],
  Trompete: ["Trompete", "Fluegelhorn", "Cornet"],
  Horn: ["Horn", "AltHorn"],
  Posaune: ["Posaune", "BassPosaune"],
  Bariton: ["Euphonium", "Bariton", "Tenorhorn"],
  Tuba: ["Tuba", "Bass", "EBass"],
  Schlagwerk: ["Schlagwerk", "Set", "Percussion"],
  Direktion: ["Direktion"],
  Gitarre: ["Gitarre"],
  Piano: ["Piano"],
  Vocals: ["Text", "Vocals"],
  Sonstige: ["Spielmannszug"],
};

export function getInstrumentGroups(): string[] {
  return Object.getOwnPropertyNames(instrumentGroups).sort();
}
