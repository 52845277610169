import { BaseApiResult } from "../baseResponse";

export class LoginResult extends BaseApiResult {
  declare token: string;
}

export class RegisterResult extends BaseApiResult {}

export class TokenBodyModel {
  declare userId: number;
  declare role: number;
  declare instrument: string;
  declare iat: number;
  declare exp: number;

  isExpired() {
    return Date.now() >= this.exp * 1000;
  }
}

export class TokenModel {
  declare token: string | null;

  constructor(token: string | null) {
    this.token = token;
  }

  toHeaderValue(): string {
    return "JWT " + (this.token ?? "UNAUTHORIZED");
  }
}
