<template>
  <h3>Musikstücke</h3>
  <select multiple class="form-select" v-model="picked" :size="compositions.length" @keypress.enter="enter">
    <option v-for="composition in compositions" v-bind:value="composition" :key="composition.id">
      {{ composition.name }}
    </option>
  </select>
  <div class="mt-2 d-grid gap-2" v-if="userStore.accessLevel > 2">
    <button class="btn btn-primary" @click="download" :disabled="downloadDisabled" title="Nur für den Notenwart">
      Download: Komplettsatz
    </button>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Composition } from "@/_utility_/backend/apiModels/compositions";
import { userStore } from "@/_utility_/stores/userStore";

@Options({
  props: {
    compositions: Array<Composition>,
    downloadDisabled: false,
  },
  emits: ["selectedComposition", "download"],
  watch: {
    picked(compositions) {
      this.$emit("selectedComposition", compositions, false);
    },
  },
})
export default class Compositions extends Vue {
  picked = [] as Composition[];
  declare compositions: Composition[];
  declare downloadDisabled: boolean;
  userStore = userStore();

  enter(): void {
    this.$emit("selectedComposition", this.picked, true);
  }
  download(): void {
    this.$emit("download");
  }
}
</script>
