import { plainToInstance } from "class-transformer";
import { from } from "linq-to-typescript";
import apiConfig from "./apiConfig";
import { Composition, CompositionResponse } from "./apiModels/compositions";
import { Instrument, InstrumentsRequest, InstrumentsResponse } from "./apiModels/instruments";
import { PartResponse } from "./apiModels/part";
import { PartsRequest, PartsResponse } from "./apiModels/parts";
import { LoginResult, RegisterResult } from "./apiModels/user";
import { extractFileName } from "./apiUtils";
import { IHttpClient, HttpMethod, isSuccess } from "./httpClient";
import { inject, injectable } from "inversify";
import { TYPES } from "../infrastructure/types";

const unknownErrorMessage =
  "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut oder kontaktiere den Administrator.";

export interface IApi {
  login(username: string, password: string): Promise<LoginResult>;
  register(username: string, password: string, instrument: string): Promise<RegisterResult>;
  getCompositions(): Promise<CompositionResponse>;
  getInstruments(compositions: Composition[]): Promise<InstrumentsResponse>;
  getParts(compositions: Composition[], instrument: Instrument): Promise<PartsResponse>;
  downloadPart(compositionId: string, partId: string): Promise<PartResponse>;
  downloadPartsForInstrument(compositionIds: string[], instrumentId: string): Promise<PartResponse>;
  downloadPartsForComposition(compositionId: string): Promise<PartResponse>;
}

@injectable()
export class Api implements IApi {
  constructor(@inject(TYPES.HttpClient) private httpClient: IHttpClient) {}
  async login(username: string, password: string): Promise<LoginResult> {
    const url = this.getUrl("/users/login");
    const response = await this.httpClient.send(url, HttpMethod.Post, {
      username,
      password,
    });
    let loginResponse = new LoginResult();
    const success = isSuccess(response);

    if (success) {
      const json = (await response.json()) as LoginResult;
      loginResponse = plainToInstance(LoginResult, json);
    }
    loginResponse.success = success;
    return loginResponse;
  }

  async register(username: string, password: string, instrument: string): Promise<RegisterResult> {
    try {
      const url = this.getUrl("/users");
      const response = await this.httpClient.send(url, HttpMethod.Post, {
        username,
        password,
        instrument: instrument.trim().length > 0 ? instrument.trim() : null,
      });
      if (response.status === 409) return { success: false, errorMessage: "Benutzer existiert bereits." };
      return { success: isSuccess(response) };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        errorMessage: unknownErrorMessage,
      };
    }
  }

  async getCompositions(): Promise<CompositionResponse> {
    const url = this.getUrl("/data/compositions");
    const response = await this.httpClient.send(url, HttpMethod.Get);
    let result = new CompositionResponse();
    const success = isSuccess(response);

    if (success) {
      const json = (await response.json()) as CompositionResponse;
      result = plainToInstance(CompositionResponse, json);
    }
    result.success = success;
    return result;
  }

  async getInstruments(compositions: Composition[]): Promise<InstrumentsResponse> {
    const url = this.getUrl("/data/instruments");
    const body = new InstrumentsRequest(compositions);
    const response = await this.httpClient.send(url, HttpMethod.Post, body);
    let result = new InstrumentsResponse();
    const success = isSuccess(response);

    if (success) {
      const json = (await response.json()) as InstrumentsResponse;
      result = plainToInstance(InstrumentsResponse, json);
    }
    result.success = success;
    return result;
  }

  async getParts(compositions: Composition[], instrument: Instrument): Promise<PartsResponse> {
    const url = this.getUrl("/data/parts");
    const body = this.createPartsRequest(compositions, instrument);
    const response = await this.httpClient.send(url, HttpMethod.Post, body);
    let result = new PartsResponse();
    const success = isSuccess(response);

    if (success) {
      const json = (await response.json()) as PartsResponse;
      result = plainToInstance(PartsResponse, json);
    }
    result.success = success;
    return result;
  }

  async downloadPart(compositionId: string, partId: string): Promise<PartResponse> {
    const url = this.getUrl("/data/part");
    url.searchParams.append("compositionId", compositionId);
    url.searchParams.append("partId", partId);

    const response = await this.httpClient.send(url, HttpMethod.Get);
    const result = new PartResponse();
    const success = isSuccess(response);

    if (success && response.body && response.headers && response.headers.has("content-disposition")) {
      result.fileStream = await response.arrayBuffer();
      const fileName = extractFileName(response);
      if (!fileName) result.success = false;
      else result.fileName = fileName;
    }
    result.success = success;
    return result;
  }

  async downloadPartsForInstrument(compositionIds: string[], instrumentId: string): Promise<PartResponse> {
    const url = this.getUrl("/data/instrumentParts");
    compositionIds.forEach((compositionId) => url.searchParams.append("compositionIds[]", compositionId));
    url.searchParams.append("instrumentId", instrumentId);

    const response = await this.httpClient.send(url, HttpMethod.Get);
    const result = new PartResponse();
    const success = isSuccess(response);

    if (success && response.body && response.headers && response.headers.has("content-disposition")) {
      result.fileStream = await response.arrayBuffer();
      const fileName = extractFileName(response);
      if (!fileName) result.success = false;
      else result.fileName = fileName;
    }
    result.success = success;
    return result;
  }

  async downloadPartsForComposition(compositionId: string): Promise<PartResponse> {
    const url = this.getUrl("/data/compositionParts");
    url.searchParams.append("compositionId", compositionId);

    const response = await this.httpClient.send(url, HttpMethod.Get);
    const result = new PartResponse();
    const success = isSuccess(response);

    if (success && response.body && response.headers && response.headers.has("content-disposition")) {
      result.fileStream = await response.arrayBuffer();
      const fileName = extractFileName(response);
      if (!fileName) result.success = false;
      else result.fileName = fileName;
    }
    result.success = success;
    return result;
  }

  private getUrl(endpoint: string): URL {
    const url = apiConfig.baseUrl + endpoint;
    return new URL(url);
  }

  private createPartsRequest(compositions: Composition[], instrument: Instrument) {
    const compositionIds = from(compositions)
      .select((composition) => composition.id)
      .toArray();
    return new PartsRequest({
      compositionIds: compositionIds,
      instrumentId: instrument.id,
    });
  }
}
