<template>
  <div>
    <h1>Administration</h1>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { requireAdminOrRedirect } from "@/_utility_/infrastructure/routingUtils";

@Options({})
export default class Administration extends Vue {
  beforeMount(): void {
    requireAdminOrRedirect(this.$router);
  }
}
</script>
