import { TokenBodyModel } from "./apiModels/user";

export default class UserInfo {
  userId: number;
  accessLevel: number;
  instrument: string;

  setUserInfo(tokenBody: TokenBodyModel) {
    this.userId = tokenBody.userId;
    this.accessLevel = tokenBody.role;
    this.instrument = tokenBody.instrument;
    return this;
  }

  constructor() {
    this.userId = 0;
    this.accessLevel = 0;
    this.instrument = "";
  }
}
