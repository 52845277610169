import { IAsyncEnumerable } from "linq-to-typescript";
import { PDFDocument } from "pdf-lib";
import { PartResponse } from "./backend/apiModels/part";

export async function mergeFiles(partResponses: IAsyncEnumerable<PartResponse>): Promise<Buffer> {
  const pdfDoc = await PDFDocument.create();
  await partResponses
    .eachAsync(async (partResponse) => {
      const fileBytes = partResponse.fileStream;

      const fileAsDoc = await PDFDocument.load(fileBytes);

      const pages = await pdfDoc.copyPages(fileAsDoc, fileAsDoc.getPageIndices());

      pages.forEach((page) => pdfDoc.addPage(page));
    })
    .count();

  return (await pdfDoc.save()) as Buffer;
}
