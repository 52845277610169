import { from } from "linq-to-typescript";
import { IApi } from "./backend/api";
import { Composition } from "./backend/apiModels/compositions";
import { Instrument } from "./backend/apiModels/instruments";
import { Part } from "./backend/apiModels/parts";
import { mergeFiles } from "./pdfUtility";
import { diContainer } from "./infrastructure/diContainer";
import { TYPES } from "./infrastructure/types";

export async function downloadParts(parts: Part[]): Promise<number> {
  const downloads = from(parts).selectAsync(async (part) => await getApi().downloadPart(part.composition.id, part.id));
  return await downloads
    .eachAsync(async (partResponse) => {
      await downloadBytes(partResponse.fileStream, partResponse.fileName);
    })
    .count();
}

export async function downloadPartsForInstrument(compositions: Composition[], instrument: Instrument): Promise<void> {
  const compositionIds = from(compositions)
    .select((composition) => composition.id)
    .toArray();
  const partResponse = await getApi().downloadPartsForInstrument(compositionIds, instrument.id);
  await downloadBytes(partResponse.fileStream, partResponse.fileName);
}

export async function downloadPartsForComposition(composition: Composition): Promise<void> {
  const partResponse = await getApi().downloadPartsForComposition(composition.id);
  await downloadBytes(partResponse.fileStream, partResponse.fileName);
}

export async function downloadPartsMerged(parts: Part[]): Promise<void> {
  const downloads = from(parts).selectAsync(async (part) => await getApi().downloadPart(part.composition.id, part.id));
  const merged = await mergeFiles(downloads);
  await downloadBytes(merged, parts[0].instrument.name + ".pdf");
}

async function downloadBytes(buffer: ArrayBuffer, name: string): Promise<void> {
  const link = document.createElement("a");
  link.style.display = "none";
  const blob = new Blob([buffer], { type: "application/pdf" });
  const objectURL = URL.createObjectURL(blob);

  link.href = objectURL;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function getApi() {
  return diContainer.get<IApi>(TYPES.Api);
}
