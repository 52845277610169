import { createRouter, createWebHistory } from "vue-router";
import NoteSelection from "@/components/NoteSelection/NoteSelection.vue";
import Administration from "@/components/administration/Administration.vue";

const routes = [
  { path: "/", component: NoteSelection },
  { path: "/admin", component: Administration },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
